<!--
 * @Descripttion:
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-08 14:44:08
-->
<template>
	<div>
		<div>
			<!-- 项目信息 -->
			<div class="pad-row" ref="content">
				<div class="whiteBg pad-row tt">
					<p><span>项目编号</span><span>{{project.projectNum}}</span></p>
					<p><span>项目名称</span><span>{{project.projectName}}</span></p>
					<p><span>采购单位</span><span>{{project.purchaseOrg}}</span></p>
					<p><span>评审时间</span><span>{{project.reviewDatetime}}</span></p>
					<p><span>评审地点</span><span>{{project.reviewSite}}</span></p>
				</div>
				<div class="whiteBg pad-row mar-t2 tt">
					<p><span>包组编号</span><span>{{project.packageNum}}</span></p>
					<p><span>专家人数</span><span>{{project.expertNum}}</span></p>
					<p class="flex-between" style="display:flex;width:100%">
						<span>评审专业</span>
						<button class="btn f35" v-show="canAdd"
							style="height:0.7rem;padding:0.05rem 0.15rem;line-height:0.6rem"
							@click.prevent="onSelectMajors">选择专业</button>
					</p>
				</div>
				<div class="whiteBg" style="padding:0.2rem 0.3rem">
					<ul class="w-100">
						<li class="flex-between" v-for="(item,index) in selectedMajors" style="margin-top: 0.2rem">
                            <span class="center van-ellipsis greenLi fw">
                                <i class="del" v-if="!item.old" @click="removeChoosedMajor(item,index)"></i>
                                {{ item.fullName }}
                            </span>
                            <input
                                type="tel"
                                @input="onInput($event.target.value,item)"
                                placeholder="指定人数"
                                class="step"
                                v-model="item.num"
                                readonly
                                disabled
                            >
                        </li>
					</ul>
				</div>
				<div>
					<!-- 轮次 -->
					<div class="whiteBg mar-t2 pad25" v-for="(item,index) in rounds" :key="index+'round'">
						<div class="flex-between">
							<p style="color:#A6A6A6" class="f3">第{{item.roundNum}}轮专家确认</p>
						</div>
						<div class="flex-item" v-for="(expertItem,index) in item.experts" :key="index+'expert'">
							<p class="fc fw w15">{{expertItem.name}}</p>
							<p class="c4">{{expertItem.mobile}}</p>

							<p class="gray2 w15 flex-between" v-if="expertItem.confirm=='0'" @click="showConfirm(expertItem)">
								<span class="f35">请确认</span>
								<i class="icon-arrow wh06"></i>
							</p>
							<p class="gray2 w15 flex-between" v-else-if="expertItem.confirm=='1'">
								<span class="f35" style="color:#00A8FF">参与</span>
							</p>
							<p class="gray2 w15 flex-between" v-else-if="expertItem.confirm=='2'">
								<span class="f35" style="color:#FF6F00">不参与</span>
							</p>
						</div>

					</div>
				</div>
			</div>
			<div style="height:1.8rem"></div>
			<!---操作步骤-->
			<div class="fixed-bottom whiteBg w-100 pad25">
				<button @click="terminateProject()">终止项目</button>
				<button class="w-100 btn" v-show="rbBtn =='1'" @click="next()">下一步</button>
				<button class="w-100 btn" v-show="rbBtn =='2'" @click="supplyExtract()">补抽</button>
                <button class="w-100 btn" v-show="rbBtn =='3'" @click="next">完成</button>
            </div>
		</div>

		<!-- 专家确认弹框 -->
		<van-popup v-model="confirmDlg" style="width:70%; border-radius:0.3rem" >
			<div class="temp whiteBg">
			    <h3 class="fc f5">{{confirmExpert.name}}</h3>
			    <p>
			        手机号：{{confirmExpert.mobile}}
			        <a :href="'tel:' + confirmExpert.mobile"><i class="icon-phone wh05 mar-l2"></i></a>
			    </p>
			    <p>是否参与</p>
			    <van-radio-group v-model="isJoin" direction="horizontal" checked-color="#00CCB2" class="mar-t2">
			        <van-radio name="1">参加</van-radio>
			        <van-radio name="2">不参加</van-radio>
			    </van-radio-group>

			    <textarea rows="5" v-model="explain" class="mar-t w-100" placeholder="请在这里输入不参与的原因…" v-show="isJoin =='2'"></textarea>
				<div class="center">
					<button class="btn f35" @click="doConfirm()">确定</button>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
/**
 *  抽取专家后需确认专家是否参与
 *  全部确认完成后，若不足专家人数，可补抽
 *  点击 补抽后 抽取一个符合条件的专家，抽取时无 动画
 *
 * **/
import expertList from  '@/test/expertList'
import { Popup,  Stepper, Switch,RadioGroup, Radio} from 'vant'
export default {
	data(){
		return{
			//项目
			project:{},
			//抽取规则
			criterias:[],
			//映射关系
			maps:[],
			//轮次
			rounds:[],
			//抽取次数
			extractedTimes:0,
			//弹层确认
			confirmDlg:false,
			//待确认专家
			confirmExpert:{},
			//是否参加
			isJoin:'1',
			//不参与原因
			explain:'',
			//抽完
			extractReached:false,
			//进入来源:0,step1;1,专业选择;
			fromVal:'0',
			//评审专业抽取人数
			// majorsNum:[],
			//选中的专业数组
			selectedMajors:[],

            // 已确认参与的专家
            confirmExpertList : [],
            // 已被抽取的专家
            extractExpertList: [],
			//
			firstExtractNoResult:true,

            // 创建时，专业指定的专家数 和 项目专家树是否相同： 相同：不可再次增加 专业
            canAdd: true,
            /** 右下按钮显示控制: 1,下一步; 2, 补抽, 3, 完成 **/
            rbBtn: 1,
		}
	},
	components:{
      [Popup.name]:Popup,
      [Stepper.name]:Stepper,
      [Switch.name]:Switch,
	  [Radio.name]:Radio,
	  [RadioGroup.name]:RadioGroup
	},
    beforeMount(){
        this.$emit('onActive',2)
    },
	created() {
        /** 从缓存中获取项目信息 */
        this.project = this.$ls.get('demo-project') || {}
        if(!this.project.projectName){
            this.$router.push('demo-1')
            return
        }

        /** 从缓存中获取专业及抽取的专家信息 **/
		this.selectedMajors = this.$ls.get('major-demo-list') || []
        if(this.selectedMajors.length == 0) {
            this.$router.push('/demo')
            return
        }
        console.log("--------专业及抽取的专家信息----------------");
        console.log(this.selectedMajors);
        let tempTotal = 0
        for (let i = 0; i < this.selectedMajors.length; i++) {
            const element = this.selectedMajors[i];
            tempTotal += parseInt(element.tempNum)
        }
        if(tempTotal >= parseInt(this.project.expertNum)){
            this.canAdd =false
        }

        this.rounds = this.$ls.get('rounds-demo') || []
        console.log("---------- 抽取轮次信息 -----------");
        console.log(this.rounds);

        this.confirmExpertList = this.$ls.get('confirm-expert-list-demo') || []
        console.log("---------- 已确认参与专家  -----------");
        console.log(this.confirmExpertList);

        this.$nextTick(
            () =>{
                window.scrollTo(0,document.documentElement.clientHeight);
            }
        )
	},

    watch: {
        /** 若累计确认参与专家不足 项目要求的专家数量，右下按钮则一直为 补抽 **/
        rounds: {
            handler(newV){
                let confirmExpertTotal = 0
                for (let i = 0; i < newV.length; i++) {
                    const round = newV[i];
                    for (let j = 0; j < round.experts.length; j++) {
                        const expert = round.experts[j];
                        if (expert.confirm == '1') {
                            confirmExpertTotal++
                        }

                    }
                }
                if(confirmExpertTotal < this.project.expertNum)
                    this.rbBtn = '2'
                else
                    this.rbBtn = '3'
            },
            deep: true
        }
    },

	methods:{

		//选择专业
		onSelectMajors:function(){
            this.$ls.set('major-demo-list',this.selectedMajors)
			this.$router.push({
				name: 'demoMajor',
                params:{
                    fromRouter: 'demo-3'
                }
			})
		},

		//终止项目
		terminateProject:function(){
            this.$dialog.confirm({
                title: '确定终止项目吗？',
                message: '终止后不可恢复，请谨慎操作！'
            }).then(() =>{  // 确认 后的回调
                /** 清除Demo中的所有缓存 **/
                this.$ls.remove('major-demo-list')
                this.$ls.remove('demo-project')
                this.$ls.remove('rounds-demo')
                this.$ls.remove('confirm-expert-list-demo')
                this.$router.push('/demo-1')
            }).catch( () =>{ // 取消 的 回调
            })

		},
		//确认
		doConfirm:function(){
			//判断原因是否填写
			if(this.isJoin=='2'){
				let explainTmp = this.explain.replace(/\s+/g, "")
				if(explainTmp==''){
					this.$toast("请填写不参加原因！")
					return
				}
			}
            /** 更新该专家的确认情况并写入缓存 **/
            if(this.isJoin == '1'){ // 确认参与
                this.confirmExpertList.push(this.confirmExpert)
                this.$ls.set('confirm-expert-list-demo',this.confirmExpertList, 2 * 60 * 60 * 1000)

                /** 根据专业更新数据 */
                for (let a = 0; a < this.selectedMajors.length; a++) {
                    const element = this.selectedMajors[a];

                }
            }
            let currentRound = this.rounds[this.rounds.length-1]
            for (let i = 0; i < currentRound.experts.length; i++) {
                if(this.confirmExpert.id == currentRound.experts[i].id){
                    currentRound.experts[i].confirm = this.isJoin
                    currentRound.experts[i].explain = this.explain
                    break
                }
            }
            this.$ls.set('rounds-demo',this.rounds, 2 * 60 * 60 * 1000)
            this.confirmDlg = false
		},
		//显示
		showConfirm:function(expert){
			//默认参加
			this.isJoin = '1'
			//原因置空
			this.explain = ''
			//传入当前专家对象
			this.confirmExpert = expert
			//显示弹层
			this.confirmDlg = true
		},
		//补抽
		supplyExtract:function(){
            /** 判断当前轮次是否有未确认的专家 **/
            let currentRoundExperts = this.rounds[this.rounds.length-1].experts || []
            let currentRoundNum = this.rounds[this.rounds.length-1].roundNum || 1
            if(parseInt(currentRoundNum) >=3){
                // this.$toast({
                //     message: '小主，对不起!\n测试环境最多只允许补抽2次',
                //     type: 'fail',
                //     className: 'div7'
                // })

                this.$dialog.alert({
                    title: '提示',
                    message: '小主，对不起!\n测试环境最多只允许补抽2次\n该项目将被废弃',
                }).then(() => {
                    /** 清除Demo中的所有缓存 **/
                    this.$ls.remove('major-demo-list')
                    this.$ls.remove('demo-project')
                    this.$ls.remove('rounds-demo')
                    this.$ls.remove('confirm-expert-list-demo')
                    this.$router.push('/demo-1')
                });
                return
            }
            for (let i = 0; i < currentRoundExperts.length; i++) {
                if(currentRoundExperts[i].confirm == '0'){
                    this.$toast({
                        message: '当前轮次还有未确认专家',
                        type: 'fail',
                        className: 'div5'
                    })
                    return
                }
            }
            /** 判断新的抽取条件中， 指定人数是否超出项目指定的专家人数 */
            /** 0316:废弃该判断条件： 新的抽取条件： 创建项目后，可增加专业但是不可再指定人数，顾 指定人数 只检验一次即可 */
            // let currentExpertNum  = 0
            // for (let j = 0; j < this.selectedMajors.length; j++) {
            //     const expert = this.selectedMajors[j];
            //     if(expert.num){
            //         let numReg = /^[1-9][0-9]*$/
            //         if(!numReg.test(expert.num)){

            //             this.$toast({
            //                 message: expert.name  + '\n请输入正确的指定人数',
            //                 type: 'fail',
            //                 className: 'div5'
            //             })
            //             return false
            //         }
            //         expert.tempNum = expert.num
            //     }else{
            //         expert.tempNum = 1
            //     }
            //     currentExpertNum += parseInt(expert.tempNum)
            // }
            // if(this.project.expertNum < currentExpertNum){
            //     this.$toast({
            //         message: '指定人数超出指定的专家人数\n请修改',
            //         type: 'fail',
            //         className: 'div5'
            //     })
            //     return false
            // }
            console.log('----- 开始补抽 ------')
            let newRound = {
                roundNum: currentRoundNum+1,
                experts: []
            }
            for (let i = 0; i < this.selectedMajors.length; i++) {
                const tt = this.selectedMajors[i];
                tt.tempNum = tt.num || 1
                tt.old = true

            }
            let chazhi = (parseInt(this.project.expertNum) -  this.confirmExpertList.length)
            for (let j = 0; j < chazhi; j++) {
                let expert = this.getExpert()
                if(typeof expert =="undefined" || expert == null){
                    console.log('专家为空');
                    expert = {
                        id: 'test' + j,
                        name: '专家B' + newRound.roundNum + j,
                        confirm: '0',
                        mobile: '186688' + '' +  Math.floor(Math.random() * 100000)
                    }
                }else{
                    expert.confirm = 0
                }
                newRound.experts.push(expert)
            }


            this.rounds.push(newRound)
            this.$nextTick(() =>{
                window.scrollTo(0,document.documentElement.clientHeight);
            })
            this.$ls.set('rounds-demo',this.rounds, 2 * 60 * 60 * 1000)

		},
		//下一步
		next:function(){
			//校验是否都确认
			let noConfirm = false
            let confirmedNum = 0
			for(let ii=0;ii<this.rounds.length;ii++){
				if(this.rounds[ii].experts){
					for(let jj=0;jj<this.rounds[ii].experts.length;jj++){
						if(this.rounds[ii].experts[jj].confirm=='0'){
							noConfirm = true
                            break
						}else if(this.rounds[ii].experts[jj].confirm=='1'){
                            confirmedNum++
                        }
					}
                    if(noConfirm) break
				}
			}
			if(noConfirm){
				this.$toast("有专家未确认，请先确认！")
				return
			}
			//查看当前已抽取数量是否满足本次项目要抽取人数
			if(this.rbBtn !='3' && this.project.expertNum>confirmedNum){
				this.$toast("已确认参与专家人数不足，少于需要的专家人数！")
				return
			}

            this.$router.push('/demo-4')
		},
		//移除选择的评审专业
		removeChoosedMajor:function(obj,index){
            this.selectedMajors.splice(index,1)
		},

        onInput(val,item){
           if(val[val.length-1] == "-" ){
                item.num = val.substr(0,val.length-1)
           }
        },

        getExpert(){
            let roundNum = Math.floor( Math.random() * 100);
            let tempExpert = expertList.list[roundNum];
            let anewDo = false

            if(tempExpert.ban =='1'){
                this.getExpert()
            }else{
                /** 遍历轮次：若轮次中已有的专家，则重新抽取 */
                for (let i = 0; i < this.rounds.length; i++) {
                    const tempRound = this.rounds[i];
                    if(tempRound.experts && tempRound.experts.length > 0){
                        for (let j = 0; j < tempRound.experts.length; j++) {
                        if(tempExpert.id == tempRound.experts[j].id){
                            anewDo = true
                            break
                        }
                        }
                        if(anewDo) break
                    }
                }
                if(anewDo){
                    console.log('已抽取过，重新抽取');
                    this.getExpert()
                }else {
                    tempExpert.ban ='1'
                    return tempExpert
                }
            }
        }
	}
}
</script>

<style scoped>
	.tt>p{display: table-row;line-height: 0.8rem;}
    .tt>p>span{display: table-cell; font-weight: 500; padding: 0.2rem 0;}
    .tt>p>span:first-child{width: 2rem;}
    .tt>p>span:last-child{color: #737373;}
    .c4{width: calc(100% - 3.5rem);}
    .flex-item{padding-top: 0.2rem;}
    .fixed-bottom>button{width: 50%; padding: 0.15rem 0.1rem; text-align: center; font-weight: bold;}
    .fixed-bottom>button:first-child{border-radius: 0.5rem 0 0 0.5rem; background-color:#F5F5F5;}
    .fixed-bottom>button:not(:first-child){border-radius:  0 0.5rem 0.5rem 0; color: white; background-color: #00CCB2;}
    .w15{width: 1.7rem;}

	.temp>p{display: inline-table; border-bottom: 1px solid #faf5f5; width: 100%;border-bottom: none; padding: 0.1rem 0;}
	.temp{padding: 0.5rem; margin-top: 0.4rem;}
	.van-radio--horizontal{margin-right: 1rem;}
	.temp button{padding-top: 0.1rem; padding-bottom: 0.1rem; width: 50%; margin-top: 1rem;}

	.fw{position: relative;}
	.delBtn{height:0.65rem;padding: 0 0.35rem;line-height:0.65rem;background-color: #FF7A7A; color: white;border-radius: 0.4rem;}
	.addBtn{height:0.65rem;padding: 0 0.35rem;line-height:0.65rem;background-color: #00CCB2; color: white;border-radius: 0.4rem; margin-left: 0.3rem;}
	.del{position: absolute; top: 0.1rem;right: 0.1rem;  width: 0.45rem; height: 0.45rem;background-color: #EDEDED; content: '-'; border-radius: 50%;}
	.del::before{width: 50%;height: 1px; content: ''; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%) rotate(45deg); background-color: #A6A6A6;}
	.del::after{width: 1px;height: 50%; content: ''; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%) rotate(45deg); background-color: #A6A6A6;}
    .greenLi{position: relative; display: inline-block; width:calc(100% - 2rem);background-color: transparent; border: none; color: #00CCB2; text-align: left;padding: 0; padding-right: 0.1rem;}
    .step {width: 2rem; flex-shrink: 0;  border-radius: 1rem; border: 1px solid #00CCB2; text-align: center; }
    .step::placeholder{font-size: 0.36rem;}
    .step:focus::placeholder{color: transparent;}
</style>